// ExperienceData.js
import WR from "../../Assets/WR.jpg";
import NL from "../../Assets/NLE.jpg";
import MU from "../../Assets/MUN.jpg";
import NA from "../../Assets/NA.jpg";
import Yekola from "../../Assets/Yekola.png";

const ExperienceData = [
  {
    title: "Yekola",
    subTitle: "Full-Stack Engineer | Remote - Toronto, canada",
    date: "Sept. 2023 - Nov. 2024",
    logoSrc: Yekola,
    techSkills: [
      "React Native", 
      "Redux ToolKit",
      "AWS Services",
      "Mongo DB",
      "Jira",
      "LLM"
    ],
    descriptions: [
      "Implemented and iterated on key functionalities for MCP, collaborating closely with product management to ensure rapid delivery and maximum value in shorter timelines.",
      "Collaboratively defined and prioritized backlog items, balancing technical debt, feature requests for both MCP and the full product using agile methodologies.",
      "Conducted code refactoring and optimization to improve system performance and scalability.",
      "Participated in code reviews and ensured adherence to coding standards and best practices.",
      "Troubleshooter and resolved complex technical issues with minimal supervision.",
    ],
  },
  {
    title: "Wind River",
    subTitle: "Cloud Engineer | Remote - Ottawa, Canada",
    date: "Sept. - Dec. 2021",
    logoSrc: WR,
    techSkills: [
      "Apache Storm", 
      "kubernetes", 
      "Data Simulation and Filtering", 
      "Documentation and Communication"
    ],
    descriptions: [
      "Refactored Kubernetes into independently deployable microservices, significantly improving operational flexibility and accelerating feature deployment cycles.",
      "Created an Apache Storm guide for simulating real-time data filtering and processing, serving as a valuable resource for data engineers.",
      "Conducted analysis of K8S deployment to identify areas for improvement, resulting in a 5% reduction in onboarding time.",
    ],
  },
  {
    title: "Wind River",
    subTitle: "Testing Developer | Remote - Ottawa, Canada",
    date: "Jan. - April. 2021",
    logoSrc: WR,
    techSkills: [
      "Technical Analysis",
      "Requirements Analysis",
      "Alignment with Specifications ",
      "High Level Requirement for ARM",
    ],
    descriptions: [
      "Analyzed code and crafted high-level test cases aligned with API Specifications to ensure seamless system integration.",
    ],
  },
  {
    title: "NLEats",
    subTitle:
      "Front-End Engineer | St. Johns, Canada",
    date: "May. - Dec. 2020",
    logoSrc: NL,
    techSkills: [
      "Team Leadership", 
      "Architectural Design", 
      "Vision Control",
      "Agile Methodologies",
      "React JS",
    ],
    descriptions: [
      "Implemented innovative algorithms for tracking delivery vehicles, utilizing Google Maps API to enhance real-time visibility of packages in transit and improve logistics efficiency.",     
      "Employed code conventions and best practices during development, participating in regular code reviews that contributed to improved code quality and maintainability.",
      "Developer responsive web application with the MERN stack while collaborating with backend developers and designers to optimize integration and user experience.",
      "Assisted in troubleshooting and debugging frontend issues, ensuring timely resolution and minimal disruption to users.",
    ],
  },
  {
    title: "Wind River",
    subTitle: "DevOps Engineer | Ottawa, Canada",
    date: "Sept. - Dec. 2019",
    logoSrc: WR,
    techSkills: [
      "Docker", 
      "Jira", 
      "Coverity", 
      "Ansible", 
      "Jenkins", 
      "CodeXM",
      "Teamwork"
    ],
    descriptions: [
      "Automated the CICD pipeline using Docker image and volumes, reducing installation time required from 5 hr to 30 min.",
      "Introduced the use of lightweight UNIX version GUI inside docker for ease of usage and better interaction for developers.",
      "Integrated Coverity Checkers for identifying security defects and vulnerabilities in the source code.",
    ],
  },
  {
    title: "Memorial University of Newfoundland - Chemistry department",
    subTitle: "Full-Stack Developer | St. John's, Canada",
    date: "May - Sept. 2019",
    logoSrc: MU,
    techSkills: [
      "React JS", 
      "Node JS", 
      "Docker", 
      "Express", 
      "MongoDB"
    ],
    descriptions: [
      "Develop front-end of the Chemistry dep “Retrievium” using ReactJS, CSS, Query, and Bootstrap",
    ],
  },
  {
    title: "North Atlantic Petroleum",
    subTitle: "Electrical Engineer | Come By Chance, Canada",
    date: "Jan. - April 2019",
    logoSrc: NA,
    techSkills: [],
    descriptions: [
      "Documented the electrical circuits and instrumentation designs for the capital project department.",
      "Assisted in maintenance the electrical units which power up the refinery.",
      "Advised in complete the instrumentation for the units in the refinery.",
    ],
  },
  // Add more company experiences here...
];

export default ExperienceData;
